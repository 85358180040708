* {
  box-sizing: border-box;
  --default-transition: color 0.3s ease-in-out,
    background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    fill 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition: var(--default-transition);
}

body {
  margin: 0;
  font-family: "Nunito Sans", "Source Sans 3", "Source Sans Pro", "sans-serif", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
